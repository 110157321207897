<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <div slot="header">
            <strong>SHOP 검색 </strong>
          </div>
          <b-input-group class="mb-2">
            <b-input-group-prepend>
              <b-button variant="primary" @click="list" :disabled="busy.list">
                <i class="fa fa-search"></i> 검색
                <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
              </b-button>
            </b-input-group-prepend>
            <b-form-input id="search" type="text" placeholder="" v-model="form.search" @keypress.enter.prevent.stop="list" autocomplete="off" v-focus></b-form-input>
          </b-input-group>
          <shop-preset v-model="form.shop" v-bind="{shopPresetOption}"></shop-preset>
          <br/>
          <b-row>
            <b-col cols="4" v-if="$R('SHOP_R')">
              <small>사용여부</small><br/>
              <b-form-radio-group class="col-form-label" id="use_yn" v-model="form.use_yn" :options="[
                {text: '전체', value: 'ALL'},
                {text: '사용', value: 'y'},
                {text: '미사용', value: 'n'}
              ]">
              </b-form-radio-group>
            </b-col>
            <b-col cols="8">
              <small>판매량 / 금액 정보 조회 기간</small><br/>
              <date-from-to :from.sync="form.orderDateFrom" :to.sync="form.orderDateTo" v-bind="{twoDays: true, absMonth: 12, year: 5}" @enter="list()">
              </date-from-to>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <small>정렬</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.sort" @change="items.list.keySort(form.sort)" :options="[
                    {text: 'Shop ID(큰순)', value: '_shop_id_desc:-1'},
                    {text: 'Shop ID', value: '_shop_id'},
                    {text: 'Shop 이름', value: 'boutique'},
                    {text: '판매 상품 수', value: 'cnt:-1'},
                    {text: 'B 최저가 상품 수', value: 'b_cf_cnt:-1'},
                    {text: 'B 최저가 상품 비중', value: 'b_cf_cnt_per:-1'},
                    {text: '전체 판매 수', value: 'order_total_cnt:-1'},
                    {text: '전체 판매금액', value: 'revenue_total_price:-1'},
                    {text: 'B 최저가 상품판매 수', value: 'b_order_cnt:-1'},
                    {text: 'B 최저가 상품 판매 금액', value: 'b_sales_price:-1'},
                  ]">
              </b-form-radio-group>
            </b-col>
          </b-row>
          <div>
            <b-button variant="primary" @click="list" :disabled="busy.list">검색
              <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div class="d-flex align-items-center" v-if="$R('SHOP_R')">
      <small class="ml-1 text-muted">판매 중 상품 수는 {{ cfStatDt }} 시점의 데이터입니다.</small>
      <div class="ml-auto">
        <b-button class="m-1" variant="light" @click="copyShopID">Shop ID 복사</b-button>
        <b-button class="m-1" variant="success" @click="down('xlsx')">XLSX</b-button>
      </div>
    </div>

    <c-table small :table-data="items.list" :fields="fields.list" :perPage.sync="perPage" :isBusy="busy.list" :caption="items.list.length + ' 개 파트너사'"></c-table>

  </div>
</template>
<script>
import {getJson, postJson} from '@/shared/api';
import {down} from '@/shared/impexp';
import * as moment from "moment-timezone";

const COL_DICT = {
  boutique: '부티크',
  parallel: '병행',
  edit_shop: '편집샵',
  etc: '기타',
  abroad: '해외',
  domestic: '국내',
  both: 'BOTH',
  balaan: '자사',
  store: '스토어',
};

export default {
  name: 'MasterOrderStat',
  title: '가격비교 (파트너별)',
  data() {
    return {
      COL_DICT,
      shop: [],
      shopMap: {},
      meta: {},
      userMap: {},
      today: moment().format('YYYY-MM-DD'),
      form: {
        shop: [],
        search: '',
        use_yn: 'y',
        sort: '_shop_id_desc:-1',
        orderDateFrom: moment().tz("Asia/Seoul").subtract(1, 'week').format('YYYY-MM-DD'),
        orderDateTo: moment().tz("Asia/Seoul").format('YYYY-MM-DD')
      },
      item: {},
      item_org: {},
      fields: {
        list: [
          {key: 'selected'},
          {key: 'shop_id', class: 'text-center'},
          {key: 'html1', label: '파트너사명', style:{maxWidth:'300px'}},
          {key: 'shop_type', label: '소싱형태', class: 'text-center'},
          {key: 'html6', label: '판매중 상품 수', class: 'text-center'},
          {key: 'html3', label: 'B최저가 상품수', class: 'text-center'},
          {key: 'html8', label: 'B최저가 상품비중', class: 'text-center'},
          {key: 'html9', label: '상품의 전체 판매수', class: 'text-center'},
          {key: 'html4', label: '상품의 전체 판매금액', class: 'text-center'},
          {key: 'html10', label: 'B최저가 상품판매수', class: 'text-center'},
          {key: 'html11', label: 'B최저가 상품판매금액', class: 'text-center'},
        ]
      },
      perPage: 20,
      lastBody: {list:{}},
      items: {list:[]},
      busy: {list: false, selected: false, random: false},
      hasMore: {list:false},
      ac: {list: null}, // abortController
      tabIndex: 0,
      modal: false,
      ruleMap: {},
      shopPresetOption: {excludeSpecialShop: true},
      collapse: {meta: false, detail: false},
      cfStatDt: '',
      selectDate: 'week',
      orderDateList: [
        {days: -1, type: 'day', value: '어제', key: 'day'},
        {days: -1, type: 'week', value: '1주일', key: 'week'},
        {days: -1, type: 'month', value: '1개월', key: 'month1'},
        {days: -3, type: 'month', value: '3개월', key: 'month3'},
        {days: -6, type: 'month', value: '6개월', key: 'month6'},
      ]
    }
  },
  async created() {
    // 마지막 상태를 가져온다
    // this.$utils.getStatus('Shop', this);

    let meta = await this.$api.getMeta('shop');
    this.shop = meta.shop.filter(e=> ![0, 204].includes(e.shop_id)).sort((a,b)=>a.shop_id-b.shop_id);
    this.shop.forEach(s=>{
      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

    await this.list();
  },
  async beforeDestroy() {
    // 마지막 상태를 저장한다
    this.$utils.setStatus('Shop', this, 'collapse');
  },
  watch: {
    tabIndex(v) {
      this.$forceUpdate();
    }
  },
  methods: {
    changeDate(days, type) {
      this.form.orderDateFrom = moment().add(days, type).format('YYYY-MM-DD');
      this.form.orderDateTo = this.today;
      if (type === 'month') {
        this.selectDate = type + (days * -1);
      } else {
        this.selectDate = type;
      }
    },
    toggleShop() {
      this.form.shop = this.form.shop.length === this.shop.length ? [] : this.shop.map(e=>e);
    },
    setShop(obj) {
      let shop = this.shop;
      Object.entries(obj).forEach(([k,v])=>{
        shop = shop.filter(e=>e[k]===v);
      });
      this.form.shop = shop;
    },
    async list() {
      this.busy.list = true;
      let shop = this.form.shop.length ? this.form.shop : this.shop;
      if (this.form.use_yn !== 'ALL') {
        shop = shop.filter(e=> e.use_yn === this.form.use_yn)
      }
      let j = await postJson('/master/orderStat', {form: {...this.form, shop: shop.map(e => e.shop_id)}});
      if (j) {
        this.cfStatDt = j.cfStatDt;
        j.list.forEach(s=>{
          this.assignTableData(s);
        });

        this.items.list = j.list.keySort(this.form.sort);
      }
      this.busy.list = false;
    },
    assignTableData(s) {
      s._org = this.$utils.clone(s);
      s.boutique = this.shopMap[s.shop_id].boutique;
      s.b_cf_cnt_per = Math.round(((s.b_cf_cnt || 0) / (s.cnt || 0) * 1000) || 0) / 10;
      if (s.b_cf_cnt_per === Infinity) s.b_cf_cnt_per = 0;

      s.value = s.boutique;
      s.shop_type = COL_DICT[this.shopMap[s.shop_id].shop_type]
      s.label = `${s.shop_id}. ${s.boutique}`;
      s.html1 = s.boutique;
      s.html6 = s.cnt || 0;
      s.html3 = s.b_cf_cnt || 0;
      s.html8 = s.b_cf_cnt_per ? `${s.b_cf_cnt_per} %` : '-';
      s.html9 = s.order_total_cnt || 0;
      s.html4 = this.$utils.comma(s.revenue_total_price);
      s.html10 = s.b_order_cnt;
      s.html11 = this.$utils.comma(s.b_sales_price);

      s._shop_id = s.use_yn === 'n' ? 10000 + s.shop_id: s.shop_id;
      s._shop_id_desc = s.use_yn === 'n' ? s.shop_id - 10000: s.shop_id;
    },
    async down() {
      let items = this.items.list.map(e=>({...e._org, boutique: e.boutique, b_cf_cnt_per: e.b_cf_cnt_per}));

      items.forEach(i=> i.b_order_cnt_per = Math.round(((i.b_order_cnt || 0) / (i.order_total_cnt || 0) * 100) || 0))
      let baseFields = '파트너사 번호,파트너사명,판매중 상품 수,B 최저가 상품 수,B 최저가 비중,상품의 전체 판매수,B 최저가 상품 판매 수,B 최저가 상품판매 비중,상품 전체 판매금액,B 최저가 상품 판매금액';
      down(items, baseFields.split(','),
          ('shop_id,boutique,cnt,b_cf_cnt,b_cf_cnt_per,order_total_cnt,b_order_cnt,b_order_cnt_per,revenue_total_price,b_sales_price').split(',')
          , `Shops_${this.$utils.dt()}`, 'xlsx');
    },
    copyShopID() {
      let selected = this.items.list.filter(e=>e.selected);
      if (!selected.length) return alert('Shop ID 를 복사할 Shop을 선택해주세요');
      let res = this.$utils.copyToClipboard(selected.map(e=>e.shop_id).join('\n'));
      if (res) this.$alertTop(`복사되었습니다`);
    },
  }
}
</script>
